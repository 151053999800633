



















































































































































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import DeleteButton from '@/components/forms/DeleteButton.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import SupportModal from '@/components/shared/SupportModal.vue';
import { minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { isValidIBAN, isValidBIC } from 'ibantools';
import PublicBankInformationService from '@/services/public/PublicBankInformationService';
import draggable from 'vuedraggable';

export class PaymentMethod {
  id!: string;
  paymentMethodType = '';
  customName!: string;
  percentageFee = '0';
  fixedFee = '0';
  address!: string;
  iban!: string;
  bic!: string;
  bank!: string;
  bankAccountName!: string;
  sortOrder!: number;
}

const mustBePositivMoneyAmount = (value: string) => {
  if (value === null) {
    return true;
  }
  return /^\d+([\,\.]\d{1,2})?$/.test(value);
};

class PlayroomEditPaymentAndInvoicePartViewModel {
  id!: string;
  paymentMethods!: PaymentMethod[];
  paymentMethodTypes!: string[];
}

const validations = {
  playroom: {
    paymentMethods: {
      $each: {
        paymentMethodType: { required },
        customName: {
          required: requiredIf((paymentMethods: PaymentMethod) => {
            return paymentMethods.paymentMethodType == 'Other';
          }),
        },
        percentageFee: { minValue: minValue(0) },
        fixedFee: {
          minValue: minValue(0),
          mustBePositivMoneyAmount,
        },
        iban: {
          ibanValidator: (iban: string) => {
            if (iban == null || iban == undefined || iban == '') return true;
            iban = iban.replace(' ', '');
            return isValidIBAN(iban);
          },
          required: requiredIf((paymentMethods: PaymentMethod) => {
            return paymentMethods.paymentMethodType == 'Invoice';
          }),
        },
        bic: {
          bicValidator: (bic: string) => {
            if (bic == null || bic == undefined || bic == '') return true;
            return isValidBIC(bic);
          },
          required: requiredIf((paymentMethods: PaymentMethod) => {
            return paymentMethods.paymentMethodType == 'Invoice';
          }),
        },
        bank: {
          required: requiredIf((paymentMethods: PaymentMethod) => {
            return paymentMethods.paymentMethodType == 'Invoice';
          }),
        },
        bankAccountName: {
          required: requiredIf((paymentMethods: PaymentMethod) => {
            return paymentMethods.paymentMethodType == 'Invoice';
          }),
        },
        address: {
          required: requiredIf((paymentMethods: PaymentMethod) => {
            return paymentMethods.paymentMethodType == 'Paypal';
          }),
        },
      },
    },
  },
  paymentMethodCount: {},
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedFormCard, SupportModal, ValidatedSelect, ValidatedInput, DeleteButton, draggable },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: new PlayroomEditPaymentAndInvoicePartViewModel(),
      ready: false,
      errorMessage: null as string | null,
      showSupportModal: false,
      paymentMethodCount: 0,
      drag: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    playroom: {
      deep: true,
      handler() {
        const hasPaymentMethod = this.playroom.paymentMethods.length > 0;
        this.$v.paymentMethodCount.$model = this.playroom.paymentMethods.length;
        const isChanged = this.$v.$anyDirty;
        if (!hasPaymentMethod && isChanged) {
          this.errorMessage = this.$t(
            'components.playroomEditParts.PaymentAndInvoicePart.hasNoPaymentMethod'
          ).toString();
        } else {
          this.errorMessage = '';
        }
      },
    },
  },
  computed: {
    stillAvailableCount(): number {
      if (this.playroom.paymentMethods == undefined) {
        return 0;
      }
      return 15 - this.playroom.paymentMethods.length;
    },

    showForm(): boolean {
      return this.$v.playroom.$model?.paymentMethods?.length > 0;
    },

    paymentMethodOptions(): { value: string; label: string }[] {
      const options = this.playroom.paymentMethodTypes.map((method) => {
        const translation = this.$t(
          'components.playroomEditParts.PaymentAndInvoicePart.paymentMethodType.' + method
        ).toString();
        return { value: method, label: translation };
      });
      return [{ value: '', label: '' }, ...options];
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditPaymentAndInvoicePart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditPaymentAndInvoicePartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
        this.$v.$reset();
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },

    onDragChange(event: { moved: { newIndex: number; oldIndex: number } }) {
      console.log(event);
      if (event.moved) {
        this.save();
        this.$v.$reset();
      }
    },

    addNewPaymentMethod() {
      if (this.stillAvailableCount >= 1) {
        const paymentMethod = new PaymentMethod();
        paymentMethod.paymentMethodType = this.playroom.paymentMethodTypes[0];
        this.playroom.paymentMethods.push(paymentMethod);
        this.$v.$touch();
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unsaved);
      }
    },

    handleDelete(index: number) {
      this.playroom.paymentMethods.splice(index, 1);
    },

    ibanChanged(paymentMethod: PaymentMethod, anyErrorOnIbanField: boolean) {
      if (paymentMethod.iban !== undefined) {
        if (!anyErrorOnIbanField) {
          PublicBankInformationService.getBankInformation(paymentMethod.iban).then((response) => {
            if (!!response.value.bic) {
              paymentMethod.bic = response.value.bic;
            }
            if (!!response.value.name) {
              paymentMethod.bank = response.value.name;
            }
          });
        }
      }
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.PaymentAndInvoicePart.info.box1';
      }
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      console.log('save');
      this.playroom.paymentMethods.forEach((paymentMethod, index) => {
        paymentMethod.sortOrder = index;
      });
      this.$v.$touch();
      console.log('this.$v.$invalid', this.$v.$invalid);
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.savePaymentAndInvoicePart({ ...this.playroom });
        return response.success;
      } else {
        return false;
      }
    },
  },
});
