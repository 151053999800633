

















































































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import AddressFormValidations from '@/validations/AddressFormValidations';
import AddressForm from '@/components/forms/AddressForm.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { required, requiredUnless, maxLength } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import { isValidIBAN, isValidBIC } from 'ibantools';
import PublicBankInformationService from '@/services/public/PublicBankInformationService';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

const taxIdValidator = helpers.regex('alpha', /^\d\d\d\/{0,1}\d\d\d\/{0,1}\d\d\d\d\d\s*$/);
const vatIdValidator = helpers.regex('alpha', /^(DE|de)\d\d\d\d\d\d\d\d\d\s*$/);

class PlayroomEditAddressViewModel {
  id!: string;
  addressRadius!: number;
  city!: string;
  country!: string;
  state!: string;
  street!: string;
  houseNumber!: string;
  zipCode!: string;
  rentTypes!: string[];
  rentType!: string;
  invoiceFirstName!: string;
  invoiceLastName!: string;
  invoiceCompanyName!: string;
  taxId!: string;
  vatId!: string;
  invoiceIban!: string;
  invoiceBic!: string;
  invoiceBank!: string;
  companyLegalName!: string;
  companyLegalType!: string;
  companyLegalTypes!: string[];
}

const validations = {
  playroom: {
    ...AddressFormValidations,
    rentType: { required },
    invoiceFirstName: { maxLength: maxLength(25) },
    invoiceLastName: { maxLength: maxLength(25) },
    invoiceCompanyName: {},
    taxId: {},
    vatId: {},
    invoiceIban: {
      ibanValidator: (iban: string) => {
        if (iban == null || iban == undefined || iban == '') return true;
        iban = iban.replace(' ', '');
        return isValidIBAN(iban);
      },
    },
    invoiceBic: {
      bicValidator: (bic: string) => {
        if (bic == null || bic == undefined || bic == '') return true;
        return isValidBIC(bic);
      },
    },
    invoiceBank: {},
    companyLegalType: {},
    companyLegalName: {},
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { AddressForm, ValidatedFormCard, ValidatedSelect, ValidatedInput },
  mixins: [validationMixin, HelpCardMixin],
  validations() {
    var vals = { ...validations };
    if (!!this.playroom.invoiceCompanyName) {
      vals.playroom.companyLegalType = { required };
    } else {
      vals.playroom.companyLegalType = {};
    }
    if (this.playroom.rentType != 'Private') {
      vals.playroom.taxId = {
        requiredTaxOrVatId: requiredUnless(function (playroom) {
          return playroom.vatId;
        }),
        taxIdValidator,
      };
      vals.playroom.vatId = {
        requiredTaxOrVatId: requiredUnless(function (playroom) {
          return playroom.taxId;
        }),
        vatIdValidator,
      };
    } else {
      vals.playroom.taxId = {};
      vals.playroom.vatId = {};
    }
    if (!!this.playroom.companyLegalType) {
      vals.playroom.invoiceCompanyName = { required, maxLength: maxLength(50) };
    } else {
      vals.playroom.invoiceCompanyName = { maxLength: maxLength(50) };
    }
    if (this.playroom.companyLegalType == 'Other') {
      vals.playroom.companyLegalName = { required };
    } else {
      vals.playroom.companyLegalName = {};
    }
    return vals;
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditAddressViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    companyLegalType: {
      immediate: true,
      handler() {
        this.resetCompanyLegalFields();
        if (this.playroom.companyLegalType == 'Other') {
          this.$v.playroom.companyLegalName?.$touch();
        }
        if (this.playroom.companyLegalType) {
          this.$v.playroom.invoiceCompanyName?.$touch();
        }
      },
    },
    invoiceCompanyName: {
      immediate: true,
      handler() {
        this.resetCompanyLegalFields();
        if (this.playroom.invoiceCompanyName) {
          this.$v.playroom.companyLegalType?.$touch();
        } else {
          this.$v.playroom.companyLegalType?.$reset();
          this.$v.playroom.invoiceCompanyName?.$reset();
        }
      },
    },
    rentType: {
      handler() {
        if (this.playroom.rentType == 'Private') {
          this.playroom.vatId = '';
          this.playroom.taxId = '';
        }
      },
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditInvoiceInformationPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditAddressViewModel;
        this.playroom.companyLegalTypes.shift();
        if (this.playroom.companyLegalType == 'None') {
          this.playroom.companyLegalType = '';
        }
        if (this.playroom.country == null) {
          this.playroom.country = 'Deutschland';
        }
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.InvoiceInformationPart.info.box1';
      }
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveInvoiceInformationPart({ ...this.playroom });
        if (response.success) {
          this.loadData();
        }
        return response.success;
      } else {
        return false;
      }
    },

    ibanChanged() {
      const iban = this.playroom.invoiceIban;
      if (this.$v.playroom.invoiceIban !== undefined) {
        if (!this.$v.playroom.invoiceIban.$anyError) {
          PublicBankInformationService.getBankInformation(iban).then((response) => {
            if (!!response.value.bic) {
              this.playroom.invoiceBic = response.value.bic;
            }
            if (!!response.value.name) {
              this.playroom.invoiceBank = response.value.name;
            }
          });
        }
      }
    },

    resetCompanyLegalFields() {
      if (this.playroom.rentType == 'Private') {
        this.playroom.invoiceCompanyName = '';
        this.playroom.companyLegalType = '';
        this.playroom.companyLegalName = '';
      }
    },
  },
  computed: {
    rentType(): string {
      return this.playroom.rentType;
    },

    showVatIdAndTaxId(): boolean {
      return this.playroom.rentType != 'Private';
    },

    rentTypeOptions(): { value: string; label: string }[] {
      return this.playroom.rentTypes.map((t) => ({
        value: t as string,
        label: this.$t('enums.rentTypes.' + t).toString(),
      }));
    },

    isCompanyDisabled(): boolean {
      return this.playroom.rentType == 'Private';
    },

    companyLegalTypeOptions(): { value: string; label: string }[] {
      if (this.ready && this.playroom.hasOwnProperty('companyLegalTypes')) {
        let cmpLegal = [];
        cmpLegal = this.playroom.companyLegalTypes.map((t) => ({
          value: t as string,
          label: this.$t('enums.companyLegalTypes.' + t).toString(),
        }));
        cmpLegal.splice(0, 0, { value: '', label: '' });
        return cmpLegal;
      } else {
        return [];
      }
    },

    companyLegalType(): string {
      return this.playroom.companyLegalType;
    },

    invoiceCompanyName(): string {
      return this.playroom.invoiceCompanyName;
    },

    isCompanyLegalNameDisabled(): boolean {
      return this.playroom.companyLegalType == 'Other';
    },
  },
});
