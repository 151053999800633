import ApiService from '@/services/ApiService';

const API_URL = '/api/BankInformation/';

class PublicBankInformationService {
  getBankInformation(iban: string) {
    return ApiService.get(`${API_URL}Get?iban=${iban}`);
  }
}

export default new PublicBankInformationService();
